
import React, { Component } from 'react';
import { Layout } from 'antd';
import './header.css'

const { Header } = Layout;

export interface HeadProps {
	backgroundImage: string;
}

export class Head extends Component<HeadProps, any> {
	constructor(props: HeadProps) {
		super(props);
	}

	render() {
		return (
			<Header className={"head-bg " + this.props.backgroundImage} >
				<div className="logo">
					<a href="/">
						<img src="./img/logo.png"/>
					</a>
				</div>
			</Header>
		)
	}
}
