import React, { Component } from 'react'
import {Row, Space, Typography} from 'antd'
import './content-with-title.css'

const {Text} = Typography

export interface ContentWithTitleProps {
	title: string;
	content: string[];
}

export default class ContentWithTitle extends Component<ContentWithTitleProps, any> {

	constructor(props: ContentWithTitleProps) {
		super(props)
	}

	render() {
	return (
		<div>
			<Row>
			<Typography.Title level={3} className="game-desc-title">
			{this.props.title}
			</Typography.Title>
			</Row>
			<Row>
			<Space direction="vertical" className="game-desc-content">
			{this.props.content.map((item, index) => {
				return <Text key={index}>{item}</Text>
			})}
			</Space>
			</Row>
		</div>
	)
	}
}
