import React, {Component} from 'react'
import {Row, Col} from 'antd'
import './images-show.css'

export interface ImagesShowProps {
	data: string[];
}


export default class ImagesShow extends Component<ImagesShowProps, any> {
	constructor(props: ImagesShowProps) {
		super(props)
	}

	render() {
		return (
			<div className="images-show">
				<Row>
					<Col span={24}>
						<h2 className="title-box">
							<span className="title">
								游戏截图
							</span>
						</h2>
					</Col>
				</Row>
				<Row>
					{this.props.data.map((url, idx) => (
						<Col span={8} key={idx}>
							<div className="image-box">
								<img className="image" src={url}/>
							</div>
						</Col>
					))}

				</Row>
			</div>
		);
	}
}
