import React from 'react';
import './App.css';
import {Row, Layout} from 'antd'
import {AutoSpace, AutoContent} from './components/layout/grid';
import {Head} from './components/header';
import {Foot} from './components/footer';
import {Home} from './ml-pages/home';
import {BrowserRouter} from 'react-router-dom';


function App() {
	return (
		<BrowserRouter>
			<div>
				<Row>
					<AutoSpace/>
					<AutoContent>
						<Layout>
							<Head backgroundImage="head-bg-img-ml"/>
							<Layout.Content>
								<Home/>
							</Layout.Content>
							<Foot/>
						</Layout>
					</AutoContent>
				</Row>
			</div>
		</BrowserRouter>
  );
}

export default App;
