
import React from 'react';

import {Layout, Row} from 'antd'
import './footer.css'
import '../css/basic.css'

const { Footer } = Layout;

export function Foot() {
	return (
		<Footer className="foot">
			<p>
				健康游戏忠告：抵制不良游戏，拒绝盗版游戏，注意自我保护，谨防上当受骗，适度游戏益脑，沉迷游戏伤身，合理安排时间，享受健康生活
			</p>
			<p> 上海汐游网络科技有限公司 版权所有</p>
			<a href="https://www.giveupgame.com/documents/register.html" target="_blank"> 用户协议 </a>
			<a href="https://www.giveupgame.com/documents/privacy.html" target="_blank"> 隐私政策 </a>
			<div className="row-white">
				<div className="beian-box">
					<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011702004851">
						<img src="./img/beian_icon.jpg"/>
						<p>沪公网安备 31011702004851号</p>
					</a>
				</div>
				<a target="_blank" href="http://218.242.124.22:8081/businessCheck/verifKey.do?showType=extShow&serial=9031000020180122100524000002332461-SAIC_SHOW_310000-20191112220326418605&signData=MEQCIFgNl1gj0YwVhk12kmSzthH8nTffMwLgCyYhmSuCEss9AiBUQd7avVeckvW3F57QFoTbWIC0yZPoO1XVmDd3DWLoFA==">
					<img src="./img/dianziyingyezhizhao.jpg" style={{width:"2%", marginRight: "10px"}}/>
				</a>

				<a href="http://www.beian.miit.gov.cn/">沪ICP备18004958号-1</a>&nbsp;&nbsp;&nbsp;
				联系电话： 13651831779&nbsp;&nbsp;&nbsp;
				上海市松江区叶榭镇镇东路18号2号楼1297室<br/>
				<br></br>
			</div>
		</Footer>
	)
}
