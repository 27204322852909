import React from 'react'
import {Row} from 'antd'
import './home.css';
import '../css/basic.css';
import ImagesShow from '../components/images-show';
import ContentWithTitle from '../components/content-with-title';

let images1 = [
	"./img/ml/screenshot-1.jpg",
	"./img/ml/screenshot-2.jpg",
	"./img/ml/screenshot-3.jpg",
]

let gameDesc = [
"快来一次轻松愉快的挖矿之旅吧~",
"无需操作的放置挖矿，简单休闲的轻度策略，不停收获金子和钻石的无尽快感~",
"按照自己的节奏进行深入探索，离线的时候也会继续挖掘获取奖励。",
"",
"- 挖掘金子、钻石，击碎邪能水晶",
"- 升级你的主角并解锁强力技能",
"- 雇佣专属于你的矿工队伍",
"- 三种地形搭配各种神秘事件",
"- 击败洞穴神秘生物获取武器与装备",
"- 不断变强，挑战新的难度",
"- 舒缓压力，维护身体健康",
]

export function Home() {
	return (
		<div>
			<Row>
				<ContentWithTitle title="《挖矿传说》" content={gameDesc}/>
			</Row>
			<Row>
				<ImagesShow data={images1}/>
			</Row>
		</div>
	)
}
