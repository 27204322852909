
import React from 'react';
import {Col} from 'antd'

export function AutoSpace({...props}) {
	return (
		<Col xs={0} sm={0} md={0} lg={0} xl={5} {...props}></Col>
	)
}

export function AutoContent({...props}) {
	return (
		<Col xs={24} sm={24} md={24} lg={24} xl={14} {...props}></Col>
	)
}
